/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  z-index: 100;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: none;
  border: 1px solid #adadad;

}

div.cs-select * {
  transition: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

div.cs-select:focus {
  outline: none; /* For better accessibility add a style for this in your skin */
}

.cs-select select {
  display: none;
}

.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
}

/* Placeholder and selected option */
.cs-select > span {
  padding-right: 38px;
}

.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-select > span::after {
  content: '';
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAAjElEQVQYV13OPQ5BURAG0PMUFiCh1FmAWuGnU0psgC0o1TqxBDYgUSpRSHQWoFMSFqAQmeS+5HlT3fnmzORmuKCBHu7+q4kjHhleqOGGDp7J1nFGC++AA+xRxRX9BA9o44NhwKgRtqjglLIuvhhjl8OYTbAu/XGKTWRFGP0My4TjvcoXyzDyRRrOi9d/tY0V/38sMFsAAAAASUVORK5CYII=') no-repeat;
  right: 14px;
  width: 10px;
  height: 7px;
}

.cs-select .cs-selected span::after {
  //content: '\2713';
  //margin-left: 1em;
}

.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

div.cs-active {
  z-index: 200;
  border-color: #474747;
  .cs-placeholder {
    border-bottom-color: #f3f3f3;
  }
  .cs-options {
    box-sizing: content-box;
    border: 1px solid #000;
    border-top: none;
    margin-left: -1px;
    li {
      border-bottom: 1px solid #e5e5e5;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cs-select ul span {
  //padding: 1em;
}

.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  //padding-left: 1em;

}

.cs-select li.cs-optgroup > span {
  cursor: default;
}

div.cs-skin-border {
  background: transparent;
  //font-size: 2em;
  font-weight: 700;
  max-width: 600px;
}

@media screen and (max-width: 30em) {
  div.cs-skin-border {
    //font-size: 1em;
  }
}

.cs-skin-border > span {
  border: 5px solid #000;
  border-color: inherit;
  -webkit-transition: background 0.2s, border-color 0.2s;
  transition: background 0.2s, border-color 0.2s;
}

.cs-skin-border > span::after,
.cs-skin-border .cs-selected span::after {
  //font-family: 'icomoon';
  //content: '\e000';
}

.cs-skin-border ul span::after {
  content: '';
  opacity: 0;
}

.cs-skin-border .cs-selected span::after {
  //content: '\e00e';
  //color: #ddd9c9;
  //font-size: 1.5em;
  //opacity: 1;
  //-webkit-transition: opacity 0.2s;
  //transition: opacity 0.2s;
}

.cs-skin-border.cs-active > span {
  background: #fff;
  border-color: #fff;
  color: #2980b9;
}

.cs-skin-border .cs-options {
  color: #2980b9;
  font-size: 0.75em;
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.cs-skin-border.cs-active .cs-options {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.cs-skin-border ul span {
  padding: 1em 2em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cs-skin-border .cs-options li span:hover,
.cs-skin-border li.cs-focus span {
  background: #f5f3ec;
}

.cs-placeholder {
  background: #f3f3f3;
}

.cs-options {
  li:hover {
    color: #1185a4;
  }
}